import { Col, Row } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import AppNavigation from "src/components/AppNavigation";
import { useSearchParams } from "react-router-dom";
import "./notification.scss";
import NotificationService from "src/services/API/Notification";
import AppVSkeleton from "src/components/skeleton/AppVSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  collection,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "src/firebase";
import AppStore, { AppDispatch, RootState } from "@store/configureStore";
import { setAllRoom, setNotices, setStatePosition } from "@store/Notice";
import { Collection, Room } from "@models/chat.model";
// import { setRoom } from "@store/Chat";
import { useDispatch, useSelector } from "react-redux";
import MyNotice from "@pages/notification/components/MyNotice";
import moment from "moment";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import _ from "lodash";
import useScrollTop from "src/hooks/useScrollTop";
import { getTotalUnreadNotice } from "src/store/Global";
import { MessageType } from "@models/common.model";
import AppPullToRefresh from "src/layouts/pull-refresh/AppPullToRefresh";
import { NOTICE_TYPE, NoticeItem, NoticeItems } from "./model";
import Tab from "./components/Tab/Tab";
import NoticeBox from "./components/noticeBox";

export default function NotificationScreen() {
  useScrollTop();

  const [currentMyNoticePage, setCurrentMyNoticePage] = useState<number>(1);
  const totalMyNotice = useRef<number>(0);
  useHideBottomBar(false);

  const currentUser = useSelector(
    (state: RootState) => state.UserReducer.userInfo
  );

  const noticeSystem = useSelector(
    (state: RootState) => state.GlobalReducer.noticeSystem
  );

  const currentState = useSelector(
    (state: RootState) => state.NoticeReducer.currentState
  );

  const scrollView = useRef(null);

  const totalUnreadNotice = useSelector(
    (state: RootState) => state.GlobalReducer.totalUnreadNotice
  );

  const dispatch = useDispatch<AppDispatch>();
  const { rooms, allRoom } = useSelector((state: RootState) => state.NoticeReducer);

  const { t: translate } = useTranslation();

  const { t } = useTranslation();

  const [searchParam, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [listNotification, setListNotification] = useState<NoticeItems[]>([]);

  const page = useRef(0);

  const totalCount = useRef(0);

  const totalCountUnread = useRef(0);

  const numberOfPage = 20;

  useEffect(() => {
    if (noticeSystem && !_.isEmpty(noticeSystem) && totalUnreadNotice) {
      setListNotification((_listNotification: NoticeItems[]) => [
        noticeSystem,
        ..._listNotification,
      ]);
      totalCountUnread.current = totalUnreadNotice;
    }
  }, [noticeSystem, totalUnreadNotice]);

  useEffect(() => {
    AppStore.dispatch(getTotalUnreadNotice());
    return () => {
      AppStore.dispatch(getTotalUnreadNotice());
    };
  }, []);

  const config = useMemo(() => {
    const initialConfig = [
      {
        label: translate("notification.tab.system"),
        value: 0,
        unreadQuantity: 0,
      },
      {
        label: translate("notification.tab.personal"),
        value: 1,
        unreadQuantity: 0,
      },
    ];

    let unreadMyNotice: number = 0;
    allRoom.forEach((room) => {
      if (!!(room.unreadMessage as any)?.[`${currentUser?.id}`]) {
        unreadMyNotice += 1;
      }
    });
    initialConfig[0].unreadQuantity = totalCountUnread.current;
    initialConfig[1].unreadQuantity = unreadMyNotice;

    return initialConfig;
  }, [allRoom, listNotification, totalCountUnread]);

  const [currentValue, setCurrentValue] = useState<NOTICE_TYPE>(
    NOTICE_TYPE.SYSTEM
  );

  const handleTabChange = (value: NOTICE_TYPE) => {
    AppStore.dispatch(getTotalUnreadNotice());
    setCurrentValue(value);
    searchParam.set("type", `${NOTICE_TYPE[value].toLocaleLowerCase()}`);
    setSearchParams(searchParam);
  };

  function getListNotification(isLoading: boolean) {
    page.current = isLoading ? 0 : page.current;
    setLoading(isLoading);
    let skip = page.current * numberOfPage;
    let count = numberOfPage;
    if (currentState) {
      skip = 0;
      count = currentState.count;
    }
    NotificationService.getListNotification(skip, count)
      .then((res) => {
        setLoading(false);
        totalCount.current = res.data.result.totalCount;
        totalCountUnread.current = res.data.result.totalUnread;
        setListNotification(
          isLoading
            ? res.data.result.items
            : listNotification.concat(res.data.result.items)
        );
        if (currentState) {
          page.current = currentState.page;
          setTimeout(() => {
            (scrollView.current as any).scrollTop = currentState?.position;
          }, 100);
        } else {
          page.current += 1;
        }
        dispatch(setStatePosition(null));
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const type = searchParam.get("type");
    setCurrentValue(
      !type || type === "system" ? NOTICE_TYPE.SYSTEM : NOTICE_TYPE.PERSONAL
    );
    getListNotification(true);
  }, [searchParam.get("type")]);

  // useEffect(() => {
  //   try {
  //     const message = { type: "JOIN_CALL", data: "OPEN VIDEO CALL" };
  //     (window as any).ReactNativeWebView.postMessage(JSON.stringify(message));
  //   } catch (e) {
  //     console.log("Send message call to app fail");
  //   }
  // }, []);

  useEffect(() => {
    if (currentUser?.id) {
      const q = query(
        collection(db, Collection.ROOMS),
        where("memberIds", "array-contains", currentUser?.id),
        orderBy("lastUpdatedAt", "desc"),
        limit(currentMyNoticePage * numberOfPage)
      );

      const unSubscribe = onSnapshot(q, (querySnapshot) => {
        console.log(178, querySnapshot);
        dispatch(
          setNotices(
            querySnapshot.docs
              .map((doc) => doc.data())
              .filter((x) => x.totalMessage > 0) as Array<Room>
          )
        );
        return () => unSubscribe();
      });
    }
  }, [currentMyNoticePage, currentUser?.id]);

  useEffect(() => {
    if (currentUser?.id) {
      const q = query(
        collection(db, Collection.ROOMS),
        where("memberIds", "array-contains", currentUser?.id),
      );

      const unSubscribe = onSnapshot(q, (querySnapshot) => {
        dispatch(
          setAllRoom(
            querySnapshot.docs
              .map((doc) => doc.data())
              .filter((x) => x.totalMessage > 0) as Array<Room>
          )
        );
        return () => unSubscribe();
      });
    }
  }, [currentMyNoticePage, currentUser?.id]);

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, Collection.ROOMS),
        where("memberIds", "array-contains", currentUser?.id)
      );
      const unSubscribe = onSnapshot(q, (querySnapshot) => {
        totalMyNotice.current = querySnapshot.size;
        return () => unSubscribe();
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (searchParam.get("type")) return;
      const q = query(
        collection(db, Collection.ROOMS),
        // orderBy("totalMessage"),
        // where("totalMessage", ">", 0),
        where("memberIds", "array-contains", currentUser?.id),
        orderBy("lastUpdatedAt", "desc"),
        limit(20)
      );
      try {
        const response = await Promise.all([
          await NotificationService.getListNotification(0, 20),
          await getDocs(q),
        ]);
        const newestSystemNotice = (response[0].data.result.items || []).find(
          (item: NoticeItem) => !item.isSeen
        );
        let newestPersonalNotice: Room = {} as Room;
        let shouldSkip: boolean = false;
        response[1].forEach((doc) => {
          if (shouldSkip) return;

          if (doc.data().unreadMessage[currentUser?.id as number]) {
            shouldSkip = true;
            newestPersonalNotice = doc.data() as Room;
          }
        });

        if (!newestSystemNotice && !Object.keys(newestPersonalNotice).length) {
          return setCurrentValue(0);
        }

        if (newestSystemNotice && !Object.keys(newestPersonalNotice).length) {
          return setCurrentValue(0);
        }

        if (!newestSystemNotice && Object.keys(newestPersonalNotice).length) {
          searchParam.set("type", "personal");
          setSearchParams(searchParam);
          return setCurrentValue(1);
        }

        if (
          moment(newestSystemNotice.sentDate).isBefore(
            moment(newestPersonalNotice.lastUpdatedAt)
          )
        ) {
          searchParam.set("type", "personal");
          setSearchParams(searchParam);
          setCurrentValue(1);
        } else {
          setCurrentValue(0);
        }
      } catch (e) {
        console.log(e);
        setCurrentValue(0);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      const message = {
        type: MessageType.LOAD_CHAT,
      };
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className="ui-notification-screen">
      <AppNavigation title={t("notification.system.title")} />

      <div className="ui-notification-screen__header">
        <h1>{t("notification.system.title")}</h1>
      </div>

      <Row gutter={[32, 32]} justify="center">
        <Col xs={24}>
          <Tab
            config={config}
            currentValue={currentValue}
            onChange={handleTabChange}
          />
        </Col>
      </Row>
      <AppPullToRefresh
        onRefresh={() => {
          // if (currentValue === NOTICE_TYPE.SYSTEM) {
            getListNotification(true);
            AppStore.dispatch(getTotalUnreadNotice());
          // } else {
            setCurrentMyNoticePage(1);
          // }
        }}
      >
        <div
          className="ui-notification-screen__items"
          id="scrollableDiv"
          ref={scrollView}
        >
          {loading ? (
            <>
              <AppVSkeleton />
              <AppVSkeleton />
              <AppVSkeleton />
            </>
          ) : currentValue === NOTICE_TYPE.SYSTEM ? (
            <InfiniteScroll
              dataLength={listNotification.length}
              next={() => {
                getListNotification(false);
              }}
              hasMore={
                listNotification.length > 0 &&
                listNotification.length < totalCount.current
              }
              loader={undefined}
              scrollableTarget="scrollableDiv"
            >
              {listNotification &&
                listNotification.length > 0 &&
                listNotification.map((item, index) => (
                  <NoticeBox
                    type={NOTICE_TYPE[currentValue].toLocaleLowerCase()}
                    key={index}
                    item={item}
                    onReload={() => {
                      getListNotification(true);
                    }}
                    onClick={() => {
                      dispatch(
                        setStatePosition({
                          page: page.current,
                          count: listNotification.length,
                          position: (scrollView.current as any)?.scrollTop,
                        })
                      );
                    }}
                  />
                ))}
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={rooms.length}
              next={() => {
                setCurrentMyNoticePage((prevState) => prevState + 1);
              }}
              hasMore={rooms.length > 0 && rooms.length < totalMyNotice.current}
              loader={undefined}
              scrollableTarget="scrollableDiv"
            >
              {!!(rooms && rooms.length) &&
                rooms.map((room) => <MyNotice key={room.roomId} room={room} />)}
            </InfiniteScroll>
          )}
        </div>
      </AppPullToRefresh>
    </div>
  );
}
