import React, { FC, useState } from "react";
import { t } from "i18next";
import moment from "moment";
import { DATE_FORMAT, PATH_NAME } from "src/configs";
import {
  ContactType,
  ProductHistoryDetail,
  ProductTransactionStatus,
} from "src/models/product.model";
import InContact from "src/components/Icons/InContact";
import InRequest from "src/components/Icons/InRequest";
import PaymentTransferCompleted from "src/components/Icons/PaymentTransferCompleted";
import PurchaseConfirmed from "src/components/Icons/PurchaseConfirmed";
import TransactionCompleted from "src/components/Icons/TransactionCompleted";
import UnderInspection from "src/components/Icons/UnderInspection";
import WaitingPayment from "src/components/Icons/WaitingPayment";
import TransactionDone from "src/components/Icons/TransactionDone";
import TransactionCancel from "src/components/Icons/TransactionCancel";
import ProductService from "src/services/API/Product";
import RequestPurchase from "src/components/Icons/RequestPurchase";
import { cloneDeep, orderBy } from "lodash";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import { TransactionType, TRANSACTION_TIMELINE } from "../../model";

export function convertToTransactionInfo(
  productHistoryDetail: ProductHistoryDetail,
  actionDetail: () => void
) {

  const formatZipCode = (zipCode: string): string => {
    if (zipCode) {
      return `${zipCode.slice(0, 3)}-${zipCode.slice(3)}`;
    }

    return '';
  };

  const checkHasZipCode = (code: string): boolean => {
    if (!code)
      return true;

    return false;
  };
  
  return [
    // Title
    {
      label: t("product.detail.title"),
      value: productHistoryDetail.productTitle,
      hasBorderBottom: true,
      valueColor: "#FF671E",
      action: actionDetail,
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // Price
    {
      label: "希望価格",
      value: (
        <>
          <span className="text-caption-2">¥</span>
          <span>
            {productHistoryDetail.productAmount.toLocaleString("en-US")}
          </span>
        </>
      ),
      valueColor: "#FF671E",
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // Fee and tax
    {
      label: t("common.commission"),
      value: (
        <div className="color-primary">
          <span className="text-caption-2">¥</span>
          <span>
            {productHistoryDetail.commissionAmountOnTax.toLocaleString("en-US")}
          </span>
          <div
            className={classNames(
              "internal-tax text-normal color-title text-caption-1",
              window.innerWidth > 992 && "internal-tax-pc"
            )}
          >
            (うち、消費税{" "}
            <NumberFormat
              value={productHistoryDetail?.consumptionTaxAmount}
              displayType="text"
              thousandSeparator
            />
            円)
          </div>
        </div>
      ),
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // Total money
    {
      label: t("product.confirm.total.amount"),
      value: (
        <div className="color-primary">
          <span className="text-caption-2">¥</span>
          <span>
            {productHistoryDetail.totalAmount.toLocaleString("en-US")}
          </span>
        </div>
      ),
      labelWidth: "40%",
      valueWidth: "60%",
    },

    // Category name
    {
      label: t("product.detail.category"),
      value: ProductService.getCategoryNameFromBE(
        `${productHistoryDetail.categoryName}`
      ),
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // Location
    {
      label: "保管場所",
      value: `${productHistoryDetail.location}`,
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // delivery address
    {
      label: "発送先住所",
      value: `〒${formatZipCode(productHistoryDetail.zipCode)} ${productHistoryDetail?.shippingAddress || ""}`,
      labelWidth: "40%",
      valueWidth: "60%",
      isHidden: checkHasZipCode(productHistoryDetail.zipCode),
    },
    // Datetime request buy
    {
      label: "購入依頼日時",
      value: moment(productHistoryDetail.purchaseRequestTime).format(
        "YYYY年MM月DD日 - HH:mm"
      ),
      isHidden:
        !productHistoryDetail.purchaseRequestTime ||
        (productHistoryDetail.orderStatus >
          ProductTransactionStatus.IN_CONFIRM &&
          productHistoryDetail.orderStatus !== ProductTransactionStatus.CANCEL),
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // Datetime confirm buy
    {
      label: "購入確定日時",
      value: moment(productHistoryDetail.purchaseConfirmationDate).format(
        "YYYY年MM月DD日 - HH:mm"
      ),
      isHidden:
        !productHistoryDetail.purchaseConfirmationDate ||
        (productHistoryDetail.orderStatus <
          ProductTransactionStatus.IN_PAYMENT &&
          productHistoryDetail.orderStatus !== ProductTransactionStatus.CANCEL),
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // Datetime contact
    {
      hasBorderBottom: false,
      label:
        productHistoryDetail.contactType === ContactType.LocalPreview
          ? "現地内見予定"
          : "オンライン見学予定",
      isHidden: !(
        productHistoryDetail.orderStatus ===
          ProductTransactionStatus.IN_CONTACT &&
        (productHistoryDetail.contactType === ContactType.LocalPreview ||
          productHistoryDetail.contactType === ContactType.OnlineTour) && productHistoryDetail.contactTime !== null
      ),
      value: moment(productHistoryDetail.contactTime).format(
        "YYYY年MM月DD日 - HH:mm"
      ),
      valueColor: "#FF671E",
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // Datetime confirmed contact
    {
      hasBorderBottom: false,
      label:
        productHistoryDetail.contactType === ContactType.LocalPreview
          ? "現地内見実施日時"
          : "オンライン見学実施日時",
      isHidden: !(
        productHistoryDetail.orderStatus >
          ProductTransactionStatus.IN_CONTACT &&
        (productHistoryDetail.contactType === ContactType.LocalPreview ||
          productHistoryDetail.contactType === ContactType.OnlineTour) && productHistoryDetail.contactTime !== null
      ),
      value: moment(productHistoryDetail.contactTime).format(
        "YYYY年MM月DD日 - HH:mm"
      ),
      valueColor: "#FF671E",
      labelWidth: "40%",
      valueWidth: "60%",
    },

    // Datetime delivery purchase
    {
      hasBorderBottom: false,
      label: "搬出予定日",
      value: moment(productHistoryDetail.scheduledDeliveryDate, DATE_FORMAT).format(
        "YYYY年MM月DD日"
      ),
      valueColor: "#FF671E",
      isHidden:
        !productHistoryDetail.scheduledDeliveryDate ||
        productHistoryDetail.orderStatus < ProductTransactionStatus.IN_CHECKING,
      labelWidth: "40%",
      valueWidth: "60%",
    },
    // Datetime received purchase
    {
      hasBorderBottom: false,
      label: "到着予定日",
      isHidden:
        !productHistoryDetail.expectedArrivalDate ||
        productHistoryDetail.orderStatus < ProductTransactionStatus.IN_CHECKING,
      value: moment(productHistoryDetail.expectedArrivalDate, DATE_FORMAT).format(
        "YYYY年MM月DD日"
      ),
      valueColor: "#FF671E",
      labelWidth: "40%",
      valueWidth: "60%",
    },
  ];
}

export const LIST_ALL: TRANSACTION_TIMELINE[] = [
  {
    status: ProductTransactionStatus.DONE,
    icon: (status: ProductTransactionStatus) => (
      <TransactionDone active={status === ProductTransactionStatus.DONE} />
    ),
    label: t(`sale.history.status.100`),
    time: "",
    note: t(`transaction.note.100`),
  },
  {
    status: ProductTransactionStatus.CONFIRM_RECEIVED,
    icon: (status: ProductTransactionStatus) => (
      <TransactionCompleted
        active={status === ProductTransactionStatus.CONFIRM_RECEIVED}
      />
    ),
    label: t(`sale.history.status.80`),
    time: "",
    note: t(`transaction.note.80`),
  },

  {
    status: ProductTransactionStatus.IN_CHECKING,
    icon: (status: ProductTransactionStatus) => (
      <UnderInspection
        active={status === ProductTransactionStatus.IN_CHECKING}
      />
    ),
    label: t(`sale.history.status.70`),
    time: "",
    note: t(`transaction.note.70`),
  },

  {
    status: ProductTransactionStatus.PAYMENT_COMPLETE,
    icon: (status: ProductTransactionStatus) => (
      <PaymentTransferCompleted
        active={status === ProductTransactionStatus.PAYMENT_COMPLETE}
      />
    ),
    label: t(`sale.history.status.60`),
    time: "",
    note: t(`transaction.note.60`),
  },

  {
    status: ProductTransactionStatus.IN_PAYMENT,
    icon: (status: ProductTransactionStatus) => (
      <WaitingPayment active={status === ProductTransactionStatus.IN_PAYMENT} />
    ),
    label: t(`sale.history.status.50`),
    time: "",
    note: t(`transaction.note.50`),
  },
  {
    status: ProductTransactionStatus.IN_CONFIRM,
    icon: (status: ProductTransactionStatus) => (
      <PurchaseConfirmed
        active={status === ProductTransactionStatus.IN_CONFIRM}
      />
    ),
    label: t(`sale.history.status.40`),
    time: "",
    note: t(`transaction.note.40`),
  },
  {
    status: ProductTransactionStatus.IN_CONTACT,
    icon: (status: ProductTransactionStatus) => (
      <InContact active={status === ProductTransactionStatus.IN_CONTACT} />
    ),
    label: t(`sale.history.status.30`),
    time: "",
    note: t(`transaction.note.30`),
  },
  {
    status: ProductTransactionStatus.IN_REQUEST,
    icon: (status: ProductTransactionStatus) => (
      <InRequest active={status === ProductTransactionStatus.IN_REQUEST} />
    ),
    label: t(`sale.history.status.20`),
    time: "",
    note: t(`transaction.note.20`),
  },
  {
    status: ProductTransactionStatus.IN_PURCHASE,
    icon: (status: ProductTransactionStatus) => (
      <RequestPurchase
        active={status === ProductTransactionStatus.IN_PURCHASE}
      />
    ),
    label: t(`sale.history.status.10`),
    time: "",
    note: t(`transaction.note.10`),
  },
];

const DELETE = {
  status: ProductTransactionStatus.CANCEL,
  icon: (status: ProductTransactionStatus) => (
    <TransactionCancel active={status === ProductTransactionStatus.CANCEL} />
  ),
  label: t(`sale.history.status.110`),
  time: "",
  note: t(`transaction.note.cancel.admin`),
};

export function convertToTransaction(productHistory: ProductHistoryDetail, transactionType: TransactionType) {
  if (productHistory.contactType === ContactType.MessageOnly) {
    productHistory.orderHistories.push({
      creationTime: productHistory.orderHistories[0].creationTime,
      status: ProductTransactionStatus.IN_REQUEST,
    });
  }
  productHistory.orderHistories = orderBy(productHistory.orderHistories, [
    "status",
  ]);

  const historyDelete = productHistory.orderHistories.filter(
    (f) => f.status === ProductTransactionStatus.CANCEL
  )[0];

  if (!historyDelete) {
    const typeListAll: TRANSACTION_TIMELINE[] = transactionType === TransactionType.PURCHASE ? LIST_ALL.filter(item => item.status !==  ProductTransactionStatus.DONE) : LIST_ALL;
    const mapData: TRANSACTION_TIMELINE[] = cloneDeep(typeListAll).map(
      (transaction) => {
        const transactionHistory = productHistory.orderHistories.filter(
          (history) => history.status === transaction.status
        )[0];
        return transactionHistory
          ? {
              ...transaction,
              time: moment(transactionHistory.creationTime).format(
                "YYYY/MM/DD HH:mm"
              ),
              note:
                  (transaction.status === ProductTransactionStatus.CONFIRM_RECEIVED 
                    && productHistory.orderStatus === ProductTransactionStatus.DONE
                    && transactionType === TransactionType.PURCHASE) 
                  ? t(`transaction.note.${transaction.status}`) 
                  : transaction.status < productHistory.orderStatus 
                  ? t("transaction.note.success")
                  : transaction.note,
            }
          : transaction;
      }
    ) as TRANSACTION_TIMELINE[];

    return mapData;
  }

  const itemDelete = {
    ...DELETE,
    note: productHistory.canceler
      ? t(`transaction.note.cancel.${productHistory.canceler}`)
      : DELETE.note,
    time: moment(
      productHistory.orderHistories.find(
        (f) => f.status === ProductTransactionStatus.CANCEL
      )?.creationTime
    ).format("YYYY/MM/DD HH:mm"),
  };

  const listReturn: TRANSACTION_TIMELINE[] = [itemDelete];

  productHistory.orderHistories.reverse().forEach((transaction, index) => {
    if (transaction.status !== ProductTransactionStatus.CANCEL) {
      const item = cloneDeep(LIST_ALL).filter(
        (history) => history.status === transaction.status
      )[0];

      if (index !== 1) {
        item.time = moment(transaction.creationTime).format("YYYY/MM/DD HH:mm");
        item.note = t("transaction.note.success");
      }
      listReturn.push(item);
    }
  });
  

  return listReturn;
}
