import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Collection, Message, Room } from "@models/chat.model";
import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@store/configureStore";
import { collection, onSnapshot, orderBy, query, where, limit } from "firebase/firestore";
import { db } from "src/firebase";
import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "src/configs";
import classNames from "classnames";
import { clearRoomIds, setRoomId } from "@store/Chat";
import { useNavigate } from 'react-router-dom';

interface Props {
  room: Room
}

const MyNotice: FC<Props> = (({ ...props }) => {
  const { roomImage, roomTitle, unreadMessage, roomId, members } = props.room;
  const currentUser = useSelector((state: RootState) => state.UserReducer.userInfo);
  const [lastMessage, setLastMessage] = useState<Message>({} as Message);
  const dispatch = useDispatch<AppDispatch>();
  const senderNameContentRef = useRef<HTMLDivElement | null>(null);
  const senderNameContainerRef = useRef<HTMLDivElement | null>(null);
  const [isFullText, setFullText] = useState<boolean>(false);
  const userName = useRef<string>("");
  const navigate = useNavigate();
  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  const senderName = useMemo(() => {
    const user = props.room?.members?.filter((member: { id: number, name: string, companyName: string }) => member.id !== currentUser?.id)?.[0] || {};
    if (Object.keys(user).length) {
      userName.current = user.name;
      // eslint-disable-next-line no-irregular-whitespace
      return `${ user.companyName ? `${user.companyName  }　${  user.name}` : user.name }`;
    }
  }, [props.room]);
  const unreadQuantity = useMemo(() => (unreadMessage as any)?.[String(currentUser?.id)], [props.room]);
  const lastSentMessageTime = useMemo(() => lastMessage.createdAt ? moment(lastMessage?.createdAt).format(moment(lastMessage?.createdAt).isSame(moment(), 'day') ? TIME_FORMAT : DATE_TIME_FORMAT) : '', [lastMessage]);

  useEffect(() => {
    const q = query(collection(db, Collection.MESSAGES), where("roomId", "==", roomId), orderBy("createdAt", "desc"), limit(1));
    const unSubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docs.forEach(doc => setLastMessage(doc.data() as Message));
    });
    return () => unSubscribe();
  }, [props.room]);

  useEffect(() => {
    const containerWidth = senderNameContainerRef.current?.offsetWidth;
    const contentWidth = senderNameContentRef.current?.offsetWidth;
    if (contentWidth && containerWidth) {
      setFullText(contentWidth > containerWidth);
    }
  }, [props.room]);

  return (
      <div className={classNames("ui-my-notice", !!unreadQuantity && "hasUnread")}
           onClick={() => {
             if (!window.navigator.onLine) {
               dispatch(clearRoomIds([]));
               navigate("/network-error");
             } else {
               dispatch(setRoomId(roomId));
             }
           }}>
        <div className="ui-my-notice__information">
          <div className="ui-my-notice__image">
            <img src={roomImage.concat(sasCode as string) || ""} alt=""/>
          </div>
          <div className="ui-my-notice__description">
            <div className="ui-my-notice__title">{roomTitle}</div>
            <div>
              <div ref={senderNameContainerRef} className="ui-my-notice__sender">
                <span ref={senderNameContentRef}>{senderName}</span>
                {
                  isFullText && (<div>{userName.current}</div>)
                }
              </div>
              <div className="ui-my-notice__time">{lastSentMessageTime}</div>
            </div>
          </div>
        </div>
        {
          !!unreadQuantity && (
            <div className="ui-my-notice__unread-quantity">{unreadQuantity > 99 ? "99+" : unreadQuantity}</div>
          )
        }
      </div>
  );
});

export default MyNotice;
