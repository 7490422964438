export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const LANGUAGE = {
  ENGLISH: "en",
  VIETNAMESE: "vn",
};

export const URL_WEB_CHAT_HUB = {
  URL: process.env.REACT_APP_API_URL,
};

export const PATH_NAME = {
  DEMO: "/demo",
  ROOT: "/",
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "change-password",
  CONFIRM_CONTACT_LG: "sales-history/confirm-contact/:id",
  RESET_PASSWORD_SUCCESS: "/reset-password/verification",
  SIGN_UP_SUCCESS: "/sign-up/verification",
  PRODUCT_DETAIL: "/product-detail/:id",
  CONTACT: "/product/:id/contact",
  CREATE_CONTACT: "/product/:id/contact/create",
  RE_CONTACT: "/product/:id/re-contact",
  EDIT_CONTACT: "/product/:id/contact/edit",
  CONTACT_PAGE: "contact",
  EDIT_PRODUCT: "/edit-product",
  PRODUCT_CONFIRM: "/product-confirm",
  HOME: "/",
  DEFAULT: "/dashboard",
  DASHBOARD: "dashboard",
  CATEGORY: "/category/:id",
  NOTIFICATION: "notification",
  SETTING: "setting",
  NOTIFICATION_SYSTEM_DETAIL: "notification/system/:id",
  NOTIFICATION_PERSONAL: "personal",
  SHOP: "shop",
  MY_PAGE: "my-page",
  MY_PAGE_FAVORITE: "favorite",
  MY_PAGE_PROFILE: "profile",
  MY_PAGE_PROFILE_EDIT: "profile/edit",
  MY_PAGE_BANK_ACCOUNT: "bank-account",
  MY_PAGE_BANK_ACCOUNT_EDIT: "bank-account/edit",
  MY_PAGE_PURCHASE_HISTORY: "purchase-history",
  MY_PAGE_PURCHASE_HISTORY_DETAIL: "purchase-history/:id",
  MY_PAGE_SALE_HISTORY_DETAIL: "sales-history/:id",
  MY_PAGE_SALE_HISTORY: "sales-history",
  MY_PAGE_QUIT_MEMBERSHIP: "quit-membership",
  CONFIRM_CONTACT: "/confirm-contact/:id",
  VISIT_ONLINE_PREPRATION: "visit-online/preparation",
};

export const DEEP_LINK = {
  SALE_HISTORY: 'my-page/sales-history',
  CONFIRM_CONTACT: 'my-page/sales-history/confirm-contact',
  PURCHASE_HISTORY: 'my-page/purchase-history',
  VISIT_ONLINE_PREPARATION: 'visit-online/preparation',
};

export const DEEP_LINK_SCREEN = [
  DEEP_LINK.CONFIRM_CONTACT,
  DEEP_LINK.SALE_HISTORY,
  DEEP_LINK.PURCHASE_HISTORY,
  DEEP_LINK.VISIT_ONLINE_PREPARATION
];

export const DATE_FORMAT = "YYYY/MM/DD";
export const YEAR_FORMAT = "YYYY";
export const VIDEO_EXTENSION = ["mp4", "MP4"];
export const IMAGE_EXTENSION = ["png", "PNG", "jpeg", "JPEG"];
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm";
export const MONTHS_IN_A_YEAR = 12;
export const END_YEAR = 1920;
// eslint-disable-next-line no-useless-escape
export const REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{7,}$/;
export const CHAT_FILE_EXTENSION = ".csv,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx";
export const CHAT_MEDIA_EXTENSION =
  ".png,.gif,.jpeg,.jpg,.heic,.JPG,.PNG,.JPEG,.GIF,.HEIC,.mp4,.MP4,.x-m4v,.mov,.MOV,video/*";
export const MAXIMUM_ROOM_MEMBERS = 2;
export const CHAT_DEFAULT_CURRENT_PAGE = 1;
export const CHAT_MESSAGE_PER_PAGE = 20;
export const CHARACTER_SPECIALS = ["+", "-", "*", ".", "!", "~", "@", "#", "$", ":", ";", ",", "=", "^", "&", "_"];
export const DEFAULT_YEAR_DATE_PICKER = 1970;
export const ALLOW_ONLY_CHARACTER = /\s|[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z]+|[ａ-ｚＡ-Ｚ]+|[々〆〤ヶ]+/u;
export const ALLOW_ONLY_CHARACTER_BANK_NAME = /^[A-Z0-9 ｧ-ﾝﾞﾟ().–]*$/;
export const ALLOW_ONLY_SPECIAL_CHARACTER = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~;×÷€£¥₫]/;
export const ALLOW_ONLY_NUMBER = /[0-9]/;
